<template>
  <div class="docManager">
    <el-card class="box-card" shadow="never">
      <el-form slot="header" class="searchFrom" size="small" :inline="true" lable-width="80px"
               :model="doc.searchForm">
        <el-form-item label="文档名称:">
          <el-input v-model="doc.searchForm.name" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="文档ID:">
          <el-input v-model="doc.searchForm.id" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="价格:">
          <el-col :span="11">
            <el-input v-model="doc.searchForm.priceMin" placeholder="" @blur="blur1"></el-input>
          </el-col>
          <el-col class="sctp-tc" :span="2">~</el-col>
          <el-col :span="11">
            <el-input v-model="doc.searchForm.priceMax" placeholder="" @blur="blur2"></el-input>
          </el-col>
        </el-form-item>
        <el-button size="small" class="sctp-square" @click="doc.resetSearchForm">重置</el-button>
        <el-button size="small" type="primary" class="sctp-square" @click="doc.loadData">搜索
        </el-button>
        <el-button @click="goPage('/shop/release/techdocs')" class="sctp-square" type="primary"
                   size="small"
                   icon="el-icon-plus">发布文档
        </el-button>
      </el-form>
      <div class="customize-table">
        <el-table
          :data="doc.data"
        >
          <el-table-column
            label="文档信息">
            <template slot-scope="item">
              <div class="flex" style="flex-wrap: nowrap;">
                <div class="sctp-bold">#{{ item.row.productId }}</div>
                <div class="sctp-bold sctp-ellipsis sctp-mar-l10 flex-item1">{{
                    item.row.productName
                  }}
                </div>
              </div>
              <div class="flex">
                <div class="sctp-mar-r10">
                  <el-image
                    style="width: 65px; height: 65px"
                    :src="item.row.productLogo"
                    fit="contain">
                    <div slot="error" class="sctp-flex sctp-flex-center sctp-bg-gray"
                         style="height: 100%;">
                      暂无图片
                    </div>
                  </el-image>
                </div>
                <div class="flex-item1 flex" style="overflow-x: auto;flex-wrap: nowrap;">
                  <template v-for="image in item.row.previews">
                    <el-image
                      style="width: 65px; height: 65px;flex-shrink: 0;"
                      :src="image"
                      fit="contain">
                      <div slot="error" class="sctp-flex sctp-flex-center sctp-bg-gray"
                           style="height: 100%;">
                        暂无图片
                      </div>
                    </el-image>
                  </template>
                </div>
              </div>
              <div class="fc-info fz-12">
                ({{ item.row.typeName }}-{{ item.row.subTypeName }})/
                <template v-if="item.row.encryptFlag === 0">不加密/</template>
                <template v-if="item.row.encryptFlag === 1">加密/</template>
                <template v-if="item.row.editFlag === 1">可编辑/</template>
                <template v-if="item.row.editFlag === 0">不可编辑/</template>
                <template v-if="item.row.watermarkFlag === 0">无水印/</template>
                <template v-if="item.row.watermarkFlag === 1">有水印/</template>
                <template v-if="item.row.docAmount">{{ item.row.docAmount + '个/' }}</template>
                <template v-if="item.row.docPage">{{ item.row.docPage + '页/' }}</template>
                <template v-if="item.row.docSize && item.row.sizeUnit">{{
                    item.row.docSize + '' + item.row.sizeUnit + '/'
                  }}
                </template>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            width="100px"
            label="售价（元）">
            <template slot-scope="scope">
              <div class="sctp-flex sctp-flex-center sctp-flex-column">
                <div style="font-weight: bold;" class="sctp-font-16">{{
                    (scope.row.productPrice || 0).toMoney()
                  }}
                </div>
                <div style="text-decoration: line-through;" class="sctp-info"
                     v-if="scope.row.underlinedPrice">
                  {{ scope.row.underlinedPrice.toMoney()}}
                </div>
                <i
                  @click="doc.onOptionBtnClick('editPrice', scope.row, scope.$index)"
                  class="el-icon-edit"></i>
              </div>
              <div class="sctp-tc" style="cursor:pointer;"
                   @click.prevent="dealModelConfig.showDialog(scope.row)">
                <el-tag size="mini">
                  {{
                    [' ', '私下交易', '担保交易', '私下/担保'][scope.row.dealModel] || ' '
                  }}
                </el-tag>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="stock"
            width="100px"
            align="center"
            label="库存/已售">
            <template slot-scope="scope">
              <div>{{ scope.row.stock }}
                <i
                  @click="doc.onOptionBtnClick('editStock', scope.row, scope.$index)"
                  class="el-icon-edit"></i>/ {{ scope.row.salesVolume }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            width="90px"
            label="是否上架">
            <template slot-scope="scope">
              <template v-if="scope.row.auditFlag !== 3">
                <el-tooltip
                  v-if="scope.row.status === 1"
                  class="item" effect="dark"
                  :content="`上架截至日期${dateToString(scope.row.shelvesDeadline)}`"
                  placement="top">
                  <el-tag size="small" effect="dark">
                    已上架
                  </el-tag>
                </el-tooltip>
                <el-tag size="small" type="info" effect="dark" v-if="scope.row.status === 0">
                  未上架
                </el-tag>
              </template>
              <template v-else>
                <el-tooltip class="item" effect="dark" content="强制下架的商品无法上架" placement="top">
                  <el-tag size="small" type="warning" effect="dark">
                    强制下架
                  </el-tag>
                </el-tooltip>
              </template>
            </template>
          </el-table-column>
          <el-table-column
            width="100px"
            align="center"
            label="审核结果">
            <template slot-scope="scope">
              <el-tag size="small" type="danger" effect="dark" v-if="scope.row.auditFlag === 0">
                未审核
              </el-tag>
              <el-tag size="small" effect="dark" v-if="scope.row.auditFlag === 1">
                已通过
              </el-tag>
              <template v-if="scope.row.auditFlag === 2 || scope.row.auditFlag === 3">
                <el-tooltip effect="dark" :content="scope.row.auditMemo" placement="top">
                  <el-tag size="small" type="danger" effect="dark">
                    {{ scope.row.auditFlag === 2 ? '未通过' : '强制下架' }}
                  </el-tag>
                </el-tooltip>
              </template>
              <el-tooltip class="item" effect="dark" content="点击查看审核历史" placement="top">
                <a
                  @click="doc.onOptionBtnClick('history', scope.row, scope.$index)"
                ><i class="el-icon-question"></i></a>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
            prop="updateTime"
            width="100px"
            label="最近更新">
            <template slot-scope="scope">
              {{ dateToString(scope.row.updateTime) }}
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作" align="center" width="120">
            <template slot-scope="scope">
              <div class="sctp-tc">
                <template v-if="scope.row.temporaryStorage">
                  <div class="sctp-mar-b5">
                    <el-button
                      size="mini" type="primary"
                      @click="doc.onOptionBtnClick('edit', scope.row, scope.$index)"
                    >
                      继续完善
                    </el-button>
                  </div>
                </template>
                <template v-else>
                  <div class="sctp-mar-b5" v-if="scope.row.status === 0">
                    <el-button
                      size="mini"
                      @click="doc.onOptionBtnClick('up', scope.row, scope.$index)"
                    >
                      上架
                    </el-button>
                  </div>
                  <div class="sctp-mar-b5" v-else>
                    <el-button
                      size="mini"
                      @click="doc.onOptionBtnClick('down', scope.row, scope.$index)"
                    >
                      下架
                    </el-button>
                  </div>
                  <div class="sctp-mar-b5">
                    <el-button
                      @click="doc.onOptionBtnClick('edit', scope.row, scope.$index)"
                      size="mini"
                    >
                      编辑
                    </el-button>
                  </div>
                  <div class="sctp-mar-b5">
                    <el-button
                      size="mini" type="primary"
                      @click="doc.onOptionBtnClick('preview', scope.row, scope.$index)"
                    >
                      预览
                    </el-button>
                  </div>
                </template>
                <div class="sctp-mar-b5">
                  <el-button
                    size="mini" type="danger"
                    :disabled="scope.row.salesVolume > 0"
                    :title="scope.row.salesVolume ? '已售文档不能删除' : ''"
                    @click="doc.onOptionBtnClick('del', scope.row, scope.$index)"
                  >
                    删除
                  </el-button>
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="sctp-pad-tb20 sctp-flex sctp-flex-fe">
        <el-pagination
          :current-page="doc.pageConfig.page"
          :page-size="doc.pageConfig.limit"
          @size-change="doc.pageConfig.handleSizeChange"
          @current-change="doc.pageConfig.handleCurrentChange"
          :page-sizes="[10, 20, 30, 50]"
          layout="prev, pager, next, total, sizes"
          :total="doc.pageConfig.total">
        </el-pagination>
      </div>
      <el-dialog width="400px" center title="选择交易方式" :visible.sync="dealModelConfig.show">
        <div style="height: 280px;">
          <el-radio-group v-model="dealModelConfig.value"
                          @change="dealModelConfig.onDealModelChange">
            <el-radio class="mg-b20" :label="1">双方私下交易</el-radio>
            <el-radio class="mg-b20" :label="2">平台担保交易</el-radio>
            <el-radio class="mg-b20" :label="3">双方私下交易/平台担保交易</el-radio>
          </el-radio-group>
        </div>
      </el-dialog>
      <el-dialog title="审核历史" class="auditHistory" :visible.sync="doc.showHistory">
        <audit-history type="DOCUMENT" :id="activeDocId"></audit-history>
      </el-dialog>
      <el-dialog width="600px" center title="文档上架需支付服务费" :visible.sync="payDialogConfig.showFlag">
        <pay-dialog type="DOCUMENT" @pay="payDialogConfig.onPay"></pay-dialog>
      </el-dialog>

      <el-dialog width="400px" center title="编辑价格" :visible.sync="updatePriceConfig.show">
        <update-price @success="updatePriceConfig.show = false;doc.loadData()" type="DOCUMENT"
                      :model="updatePriceConfig.model" :id="activeDocId"></update-price>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
import {doc, goods} from "../../../../apis";

export default {
  name: "index",
  components: {
    AuditHistory: () => import('@PAGE/users-center-manage/AuditHistory.vue'),
    UpdatePrice: () => import('@CMP/product/UpdatePrice'),
    payDialog: () => import('@PAGE/users-center-manage/requirement-manage/RequirementOnlinePay'),
  },
  methods:{
    blur1(){
      if(this.doc.searchForm.priceMax!=null&&this.doc.searchForm.priceMin!=null) {
        let a = this.doc.searchForm.priceMax * 1;
        let b = this.v.searchForm.priceMin * 1;
        if(a<b)
        {
          this.$alert("最低价格不能超过最高价格")
          this.doc.searchForm.priceMin=null
        }
      }
    },
    blur2(){
      if(this.doc.searchForm.priceMax!=null&&this.doc.searchForm.priceMin!=null) {
        let a = this.doc.searchForm.priceMax * 1;
        let b = this.doc.searchForm.priceMin * 1;
        if(a<b)
        {
          this.$alert("最高价格不能低于最低价格")
          this.doc.searchForm.priceMax=null
        }
      }
    },
  },
  data() {
    return {
      activeDocId: null,
      updatePriceConfig: {
        show: false,
        model: {},

      },
      dealModelConfig: {
        id: null,
        value: null,
        show: false,
        showDialog(item) {
          this.show = true;
          this.value = item.dealModel;
          this.id = item.productId;
        },
        onDealModelChange: (label) => {
          goods.changeDealModel({
            productId: this.dealModelConfig.id,
            value: label,
            type: 2,
          }).then(res => {
            this.$message.success('修改成功');
            this.doc.loadData();
          })
        }
      },
      doc: {
        showHistory: false,
        onOptionBtnClick: (option, item, index) => {
          this.activeDocId = item.productId;
          if (this.doc.options[option]) {
            this.doc.options[option].call(null, {item: item, index: index});
          }
        },
        options: {
          history: () => {
            this.doc.showHistory = true;
          },
          preview: ({item, index}) => {
            this.openPage({
              path: `/techdocs/view/${item.productId}`,
            });
          },
          editPrice: ({item, index}) => {
            this.updatePriceConfig.show = true;
            this.updatePriceConfig.model = {
              salePrice: item.productPrice,
              realPrice: item.underlinedPrice,
            }
          },
          editStock: ({item, index}) => {
            this.$prompt('请输入库存', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              inputPattern: /^(?!0)(?:[0-9]{1,4}|10000)$/,
              inputErrorMessage: '库存限制范围 1 - 10000'
            }).then(({value}) => {
              goods.updateStock({
                id: item.productId,
                stock: value,
                type: 'DOCUMENT',
              }).then(res => {
                item.stock = value;
                this.$message.success('修改成功');
              })
            })
          },
          del: ({item, index}) => {
            this.$confirm(`确认删除？`, '提示', {
              type: 'warning'
            }).then(() => {
              doc.del({
                id: item.productId
              }).then(res => {
                this.$message.success('删除成功');
                this.doc.loadData();
              });
            });
          },
          edit: ({item, index}) => {
            let editPage = () => {
              this.goPage({
                path: `/shop/release/techdocs/edit/${item.productId}`,
              });
            };
            if (item.auditFlag === 1) {
              this.$confirm(`${item.status === 1 ? '编辑需要先下架，' : ''}重新编辑后需要重新审核，确定编辑吗？`, '提示', {
                type: 'warning'
              }).then(() => {
                if (item.status === 1) {
                  this.doc.doChangeStatus(item.productId, 0).then(res => {
                    editPage();
                  });
                } else {
                  editPage();
                }
              });
              return;
            }
            editPage();
          },
          up: ({item, index}) => {
            if (item.auditFlag === 0 || item.auditFlag === 2) {
              this.$confirm(`您的文档暂未通过审核，无法上架`, '提示', {
                type: 'warning'
              });
              return;
            }
            if (item.auditFlag === 3) {
              this.$confirm(`您的文档由于‘${item.auditMemo || ''}’被强制下架,请重新编辑`, '提示', {
                type: 'warning'
              }).then(() => {
                this.goPage({
                  path: `/shop/release/techdocs/edit/${item.productId}`,
                });
              });
              return;
            }
            this.doc.doChangeStatus(item.productId, 1).then(res => {
              this.$message.success('上架成功')
            });
          },
          down: ({item, index}) => {
            this.doc.doChangeStatus(item.productId, 0).then(res => {
              this.$message.success('下架成功')
            });
          },
        },
        doChangeStatus: (docId, status) => {
          return doc.changeStatus({
            docId: docId,
            status: status,
            userId: this.user.userId
          }).then(res => {
            this.doc.loadData();
          }).catch(res => {
            if (res && res.retCode === '9002') {
              this.payDialogConfig.showUpperShelvesDialog();
            }
            return Promise.reject(res);
          })
        },
        data: [],
        resetSearchForm: () => {
          Object.keys(this.doc.searchForm).forEach(key => {
            this.doc.searchForm[key] = null;
          })
        },
        loadData: () => {
          let {pageConfig: {page, limit}, searchForm} = this.doc;
          doc.list({
            page: page,
            limit: limit,
            userId: this.user.userId,
            id: searchForm.id,
            docName: searchForm.name,
            priceMax: searchForm.priceMax,
            priceMin: searchForm.priceMin,
          }).then(res => {
            let {retdata, count = 0} = res;
            retdata.forEach(item => {
              if (item.showimgurls) {
                item.showimgurls = item.showimgurls.split(',');
              }
            })
            this.doc.data = retdata;
            this.doc.pageConfig.total = count;
          });
        },
        searchForm: {
          id: null,
          name: null,
          priceMin: null,
          priceMax: null,
        },
        pageConfig: {
          page: 1,
          limit: 10,
          total: 0,
          handleSizeChange: (limit) => {
            this.doc.pageConfig.page = 1;
            this.doc.pageConfig.limit = limit;
            this.doc.loadData();
          },
          handleCurrentChange: (page) => {
            this.doc.pageConfig.page = page;
            this.doc.loadData();
          }
        },
      },
      payDialogConfig: {
        showFlag: false,
        showUpperShelvesDialog: () => {
          this.payDialogConfig.showFlag = true
        },
        onPay: ({payWay, price, date}) => {
          return doc.upperPay({
            docId: this.activeDocId,
            userId: this.user.userId,
            payWay: payWay,
            date: date
          }).then(res => {
            this.payDialogConfig.showFlag = false;
            this.$message.success('操作成功');
            this.doc.loadData();
          });
        }
      },
    }
  },
  watch:{
      'doc.searchForm.priceMin'(newVal,oldVal){
        if(!(/^\d+$/.test(newVal)))
        {
          this.$alert('价格不能包含除数字以外的字符')
          this.doc.searchForm.priceMin=null
          return
        }
      },
      'doc.searchForm.priceMax'(newVal,oldVal){
        if(!(/^\d+$/.test(newVal)))
        {
          this.$alert('价格不能包含除数字以外的字符')
          this.doc.searchForm.priceMax=null
          return
        }
      },
  },
  beforeMount() {
    this.doc.loadData();
  }
}
</script>

<style scoped lang="scss">
.auditHistory ::v-deep .el-dialog__body {
  padding-top: 0;

  & .el-table {
    font-size: 12px !important;
  }
}

.docManager {
  ::v-deep {
    td {
      .el-icon-edit {
        display: none;
        cursor: pointer;
      }

      :hover .el-icon-edit {
        display: inline-block;
      }
    }

    .el-dialog {
      width: 80%;

      .el-dialog__body {
        max-height: 70vh;
        overflow-y: auto;
      }
    }
  }

}

.searchFrom {
  ::v-deep .el-form-item__content {
    width: 130px !important;
  }

  ::v-deep .el-input__inner {
    border-radius: 0;
  }
}

.view_comment_dialog ::v-deep .el-dialog__body {
  max-height: 50vh;
  overflow-y: auto;
}
</style>
